<template>
    <div class="root">
        <div class="payment-step" :class="{ hidden: hidePaymentFrame }">
            <iframe id="payment-frame" width="100%" height="100%" :src="iframeSrc">
            </iframe>
        </div>
        <div class="thank-you content" :class="{ hidden: hideThankYou }">
			<div class="message-view">
				<p>Спасибо за бронирование путевки в нашем санатории. <br>По любым вопросам вы можете звонить по телефону <a href="tel:8-800-707-5182">8 800 707 5182</a>.</p>
			</div>
        </div>
        <div class="step-4" :class="{ hidden: hideStep4 }">
        <header>
            <div class="top-header">
                <div class="header-back" @click="goToStep3()">
                    <i class="el-icon-arrow-left"></i>
                    <span>Назад к путевкам</span>
                </div>

                <div class="header-dates" @click="SET_STEP(1)">
                    <i class="las la-calendar-week"></i>
                    <span
                        >{{ formatedDate(this.START_DATE) }} -
                        {{ formatedDate(this.END_DATE) }} ({{
                            NIGHTS_INFO
                        }})</span
                    >
                </div>

                <div class="header-room" @click="SET_STEP(1)">
                    <i class="las la-user"></i>
                    <span>{{ BOOKING_INFO }}</span>
                </div>
            </div>

            <el-progress :percentage="100" :format="format()"></el-progress>
        </header>

        <BookDetailTable
            :dates="tableDates"
            :guests="tableGuests"
            :rooms="tableRooms"
            :tariffs="tableTariffs"
            :totalPrice="totalPrice"
            :key="price"
        />

        <div class="forms-block">
            <Forms
                @setSession="$emit('setSession')"
                @addingRoomsFinished="getPayType()"
                @returnToStart="$emit('returnToStart')"
                @removeAllRooms="removeAllRooms()"
                ref="formRef"
                :handlerRoomInfo="handlerRoomInfo"
                :scrollToTop="scrollToTop"
                :backendRooms="backendRooms"
                :handleInfoClick="handleInfoClick"
            />
        </div>

        <div class="info-cards-block">
            <div class="info-card" v-if="SETTINGS[0].show_cashback">
                <div class="card-content">
                    <p>
                        <b>Внимание!</b><br />Для получения кешбэка необходимо
                        выполнить все условия акции.
                    </p>
                    <ul>
                        <li>Регистрация в программе</li>
                        <li>Проживание минимум 3 дня</li>
                        <li>Даты проживания должны входить в период акции</li>
                    </ul>
                </div>
                <div class="card-footer">
                    <el-button type="link" @click="openMirRegistration"
                        >Зарегистрироваться для получения кэшбека</el-button
                    >
                    <el-button type="link" @click="handleInfoClick(5)">
                        Условия проведения акции
                    </el-button>
                </div>
            </div>

            <div class="info-cards-columns">
                <div class="info-card">
                    <p>
                        Время заезда — 08:00 - 11:00<br />Время выезда — 08:00
                    </p>
                </div>
                <div class="info-card">
                    <p>
                        <a @click="handleInfoClick(0)" href="javascript:void(0)"
                            >Правила отмены бронирования</a
                        ><br />
                        <a @click="handleInfoClick(1)" href="javascript:void(0)"
                            >Перечень необходимых документов</a
                        ><br />
                        <a @click="handleInfoClick(5)" href="javascript:void(0)"
                            >Способы оплаты</a
                        >
                    </p>
                </div>
            </div>
        </div>
		
		<h2>Выберите способ оплаты</h2>
		<div class="payment-selectors">
			<div class="payment-selector-block">
				<h4>Размер предоплаты</h4>
				<PersentSelect 
					:persentValues="percentValues"
					:selectedValue="currentPersent"
					:key="timestamp1"
					class="persent-select"
					@selectChanged="currentPersent=$event"
				/> 
			</div>
			<div class="payment-selector-block">
				<h4>Банк для оплаты</h4>
				<PaymentWaySelect 
					:paymentWayValues="paymentWayValues"
					:selectedValue="currentPaymentWay"
					:key="timestamp2"
					class="paymentway-select"
					@selectChanged="currentPaymentWay=$event"
				/> 
			</div>
        </div>
		<p>Оплатить можно картой любого банка, независимо от того, какой банк вы выберете для проведения платежа</p>
        <div
            class="payment-options"
            v-for="(item, idx) in paymentType"
            :key="idx + 777"
        >
            <!-- Оплата частями -->
            <div class="payment-card" v-if="item.PayType == 'CARD' && isInstallment(item.Description)">
                <div class="columns">
                    <div class="column description">
                        <h4>Оплата частями</h4>
                        <p>
                            Данный вид оплаты позволяет забронировать сейчас,
                            а рассчитываться потом.
                            Сервис «Плати частями» от Сбербанка.
                        </p>
                    </div>
                    <div class="column summary">
                        <div class="methods">
                            <!-- <img src="img/sberpay.png" alt="" /> -->
                            <!-- <img src="img/sbp-logo-new.png" alt="" /> -->
                            <img src="img/mastercard.png" alt="" />
                            <img src="img/visa.png" alt="" />
                            <img src="img/mir.png" alt="" />
                        </div>
                        <!-- <div class="percent">
                            Предоплата {{ item.PayProc }}%
                        </div> -->
                        <div class="price">
                            <b>{{ item.PaySum.toLocaleString("ru") }}</b> ₽
                        </div>
                        <div class="payment-row">
                            <span class="payment-label">Забронировать:</span>
                           
                            <el-button
                                type="primary"
                                class="button-card"
                                @click="booking(item.Id, 'card')"
                            >
                                Плати частями
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        <div
            class="payment-options"
            v-for="(item, idx) in paymentType"
            :key="idx"
        >
            <!-- Сбер -->
            <div class="payment-card" v-if="item.PayType == 'CARD' && item.PayProc == currentPersent 
            && item.Description.includes(currentPaymentWay) && currentPaymentWay == 'СберБанк'">
                <div class="columns">
                    <div class="column description">
                        <h4>Оплата банковской картой / SberPay</h4>
                        <p>
                            Оплата заказа онлайн через платежную систему
                            «Сбербанк». К оплате принимаются карты Visa, Visa
                            Electron, MasterCard, MasterCard Electronic, МИР.
                        </p>
                    </div>
                    <div class="column summary">
                        <div class="methods">
                            <img src="img/sberpay.png" alt="" />
                            <!-- <img src="img/sbp-logo-new.png" alt="" /> -->
                            <img src="img/mastercard.png" alt="" />
                            <img src="img/visa.png" alt="" />
                            <img src="img/mir.png" alt="" />
                        </div>
                        <div class="percent">
                            Предоплата {{ item.PayProc }}%
                        </div>
                        <div class="price">
                            <b>{{ item.PaySum.toLocaleString("ru") }}</b> ₽
                        </div>
                        <div class="payment-row">
                            <span class="payment-label">Забронировать:</span>
                            <el-button
                                type="primary"
                                class="button-sberpay"
                                @click="booking(item.Id, 'sberpay')"
                            >
                                Pay
                            </el-button>
                            
                            <el-button
                                type="primary"
                                class="button-card"
                                @click="booking(item.Id, 'card')"
                            >
                                Оплата картой
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>

             <!-- Альфа -->
             <div class="payment-card" v-if="item.PayType == 'CARD' && item.PayProc == currentPersent && item.Description.includes(currentPaymentWay) && currentPaymentWay == 'Альфа-Банк'">
                <div class="columns">
                    <div class="column description">
                        <h4>Оплата банковской картой / Alfa Pay</h4>
                        <p>
                            Оплата заказа онлайн через платежную систему
                            «Альфа-Банк». К оплате принимаются карты Visa, Visa
                            Electron, MasterCard, MasterCard Electronic, МИР.
                        </p>
                    </div>
                    <div class="column summary">
                        <div class="methods">
                            <img src="img/mastercard.png" alt="" />
                            <img src="img/visa.png" alt="" />
                            <img src="img/mir.png" alt="" />
                        </div>
                        <div class="percent">
                            Предоплата {{ item.PayProc }}%
                        </div>
                        <div class="price">
                            <b>{{ item.PaySum.toLocaleString("ru") }}</b> ₽
                        </div>
                        <div class="payment-row">
                            <span class="payment-label">Забронировать:</span>							
                            <el-button
                                type="primary"
                                class="button-alfapay"
                                @click="booking(item.Id, 'card')"
                            >
                                Pay
                            </el-button>
                            <el-button
                                type="primary"
                                class="button-card"
                                @click="booking(item.Id, 'card')"
                            >
                                Оплата картой
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>

             <!-- СБП -->
             <div class="payment-card" v-if="item.PayType == 'CARD' && item.PayProc == currentPersent && item.Description.includes(currentPaymentWay) && currentPaymentWay == 'СБП'">
                <div class="columns">
                    <div class="column description">
                        <h4>Оплата СБП</h4>
                        <p>
                            Оплата заказа онлайн через платежную систему быстрых платежей.
                            К оплате принимаются карты Visa, Visa
                            Electron, MasterCard, MasterCard Electronic, МИР.
                        </p>
                    </div>
                    <div class="column summary">
                        <div class="methods">
                            <img src="img/sbp-logo-new.png" alt="" />
                            <img src="img/mastercard.png" alt="" />
                            <img src="img/visa.png" alt="" />
                            <img src="img/mir.png" alt="" />
                        </div>
                        <div class="percent">
                            Предоплата {{ item.PayProc }}%
                        </div>
                        <div class="price">
                            <b>{{ item.PaySum.toLocaleString("ru") }}</b> ₽
                        </div>
                        <div class="payment-row">
                            <span class="payment-label">Забронировать:</span>
                            <el-button
                                type="primary"
                                class="button-sbp"
                                @click="booking(item.Id, 'sbp')"
                            >
                                Оплата СБП
                            </el-button>
                            
                        </div>
                    </div>
                </div>
            </div>



            <div class="payment-card" v-if="item.PayType == 'NOPAY'">
                <div class="columns">
                    <div class="column description">
                        <h4>Без предоплаты</h4>
                        <p>
                            Оплата заказа осуществляется по прибытию. К оплате
                            принимаются карты Visa, Visa Electron, MasterCard,
                            MasterCard Electronic, МИР, возможна оплата
                            наличными.
                        </p>
                    </div>
                    <div class="column summary">
                        <div class="methods">
                            <img src="img/mastercard.png" alt="" />
                            <img src="img/visa.png" alt="" />
                            <img src="img/mir.png" alt="" />
                        </div>
                        <div class="percent">Без предоплаты</div>
                        <div class="price"></div>
                        <div class="payment-row">
                            <!-- ------ПОД ЗАМЕНУ------ -->
                            <el-button
                                type="primary"
                                class="button-card"
                                @click="booking(item.Id, 'nopay')"
                            >
                                Забронировать
                            </el-button>
                            <!-- ------ПОД ЗАМЕНУ------ -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="payment-security" @click="$emit('returnToStart')">
            <img src="img/security.png" alt="" />
        </div> -->

        <!-- <div class="payment-guarantees">
            <p>
                Гарантии безопасности<br />Lorem ipsum
            </p>
        </div> -->
        <InfoDrawer
            :expand="isOpenInfo"
            :info="infoPages[pageIdx]"
            @collapseInfoDrawer="isOpenInfo = false"
        />

        <div class="footer-info">
            Не нашли интересующий вас вариант? Позвоните нам по телефону
            <a href="tel:8-800-707-5182">8 800 707 5182</a>, мы поможем.
        </div>
        </div>
    </div>
</template>

<script>
import BookDetailTable from "./BookDetailTable";
import InfoDrawer from "./InfoDrawer";
import Forms from "./Forms";
import PersentSelect from "./PersentSelect.vue";
import { mapActions, mapGetters } from "vuex";
import { formatDate, formatProgressBar } from "../api.js";
// import { addEmail } from "../sendPulseApi";
import { uniSubscribe } from "../unisender";
import axios from "axios";
import PaymentWaySelect from "./PaymentWaySelect.vue";
// import url from 'vuelidate/lib/validators/url';
export default {
    components: {
        BookDetailTable,
        Forms,
        InfoDrawer,
        PersentSelect,
        PaymentWaySelect
    },
    props: {
        handlerRoomInfo: Function,
        scrollToTop: Function,
        backendRooms: Array,
        showMessage: Function,
        getSpkConfig: Function,
    },
    data() {
        return {
            guestParams: this.$store.state.guestParams,
            isInfoDrawer: false,
            paymentType: [],
            price: 0,
            isOpenInfo: false,
            infoPages: [],
            pageIdx: 0,
            paymentCount: 0,
            iframeSrc: "",
            hidePaymentFrame: true,
            hideStep4: false,
            hideThankYou: true,
            percentValues: [],
            timestamp1: "",
            currentPersent: 20,
            paymentWayValues: [],
            currentPaymentWay: 'СберБанк',
            timestamp2: "", 
            totalPrice: 0
            
        };
    },
    created: async function () {
        await this.initStepFour();
    },
    mounted: async function () {
        //window.scrollTo(0, 0);
        this.scrollToTop();
        this.paymentType = [];
        this.totalPrice = this.tableTotalPrice()
    },
    computed: {
        ...mapGetters([
            "START_DATE",
            "END_DATE",
            "DAYS",
            "NIGHTS_INFO",
            "BOOKING_INFO",
            "PAYMENT_TYPE_URL",
            "DIVISION",
            "GUEST_PARAMS",
            "ROOMS",
            "TARIFFS",
            "SESSION_DATA",
            "PAYMENT_URL",
            "SETTINGS",
            "BACK_API_URL",
            "ADDED_ROOMS",
            "REMOVE_URL",
        ]),
        tableDates: function () {
            let dates = `${this.formatedDate(
                this.START_DATE
            )} - ${this.formatedDate(this.END_DATE)}`;
            return dates;
        },
        tableGuests: function () {
            let adults = 0;
            let childs = 0;
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                adults += this.GUEST_PARAMS[idx].adults.quantity;
                childs += this.GUEST_PARAMS[idx].childs.ages.length;
            }
            if (childs == 0) {
                return `${adults} взрослых`;
            }
            return `${adults} взрослых, ${childs} детей`;
        },
        tableRooms: function () {
            let roomsTitle = [];
            let result = "";
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                const roomId = this.GUEST_PARAMS[idx].roomId;
                roomsTitle.push(this.getRoomTitle(idx, roomId));
            }
            for (let idx2 = 0; idx2 < roomsTitle.length; idx2++) {
                if (idx2 > 0) {
                    result += " | ";
                }
                result += `${roomsTitle[idx2]}`;
            }
            return result;
        },
        tableTariffs: function () {
            let tariffsIds = [];
            let result = "";
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                const item = this.GUEST_PARAMS[idx];
                for (
                    let innerIdx = 0;
                    innerIdx < item.adults.tariffIds.length;
                    innerIdx++
                ) {
                    const tariff = item.adults.tariffIds[innerIdx];
                    tariffsIds.push(tariff);
                }
                for (
                    let innerIdx = 0;
                    innerIdx < item.childs.tariffIds.length;
                    innerIdx++
                ) {
                    const tariff = item.childs.tariffIds[innerIdx];
                    tariffsIds.push(tariff);
                }
            }
            for (let idx = 0; idx < tariffsIds.length; idx++) {
                const id = tariffsIds[idx].id;
                const title = this.getTariffTitle(id);
                if (idx > 0) {
                    result += " | ";
                }
                result += `${title}`;
            }
            return result;
        },
    },
    watch: {
        paymentType: {
            handler() {
                if (this.paymentType.length > 0) {
                    this.percentValues = this.fillPersentValues()
                    this.paymentWayValues = this.fillPaymentWays()
                    this.timestamp1 = Date.now()
                    this.timestamp2 = this.timestamp1 + 1
                }
                
            },
            deep: true
        },
    },

    methods: {
        ...mapActions(["SET_STEP"]),
        formatedDate(Date) {
            return formatDate(Date);
        },
        format() {
            return formatProgressBar;
        },
        async getPayType() {
            const data = [
                {
                    division: this.DIVISION,
                    land: "ru",
                },
            ];
            const res = await axios.post(this.PAYMENT_TYPE_URL, data, {
                headers: { Authorization: `Bearer ${this.SESSION_DATA}` },
            });
            let respData = res.data;
            if (respData.result == "success") {
                this.paymentType = respData.data;
            }
        },
        getRoomTitle(roomIdx, roomId) {
            for (let idx = 0; idx < this.ROOMS.length; idx++) {
                if (
                    this.ROOMS[idx].idx == roomIdx &&
                    this.ROOMS[idx].id == roomId
                ) {
                    return this.ROOMS[idx].title;
                }
            }
        },
        getTariffTitle(id) {
            for (let idx = 0; idx < this.TARIFFS.length; idx++) {
                const tariffs = this.TARIFFS[idx].tariffs;
                for (let innerIdx = 0; innerIdx < tariffs.length; innerIdx++) {
                    const tariff = tariffs[innerIdx];
                    if (tariff.tariffId == id) {
                        return tariff.title;
                    }
                }
            }
        },
        async checkPayment(paymentId) {
            let statusPayment = await this.payment(paymentId);
            if (statusPayment === "wait") {
                setTimeout(() => {
                    this.checkPayment(paymentId);
                }, 2000);
            }
            if (statusPayment === "success") {
                this.hidePaymentFrame = true
                this.hideThankYou = false
                const SITE_URL = this.$store.state.parentSiteUrl;
                parent.postMessage({ bookSuccess: '' }, SITE_URL);
            }
        },
        async payment(paymentId, payType = "check") {
            let data = [
                {
                    paymentTypeId: paymentId,
                    division: this.DIVISION,
                    URL: "https://booking-belokurikha.ru/",
                },
            ];
            return new Promise((resolve, reject) => {
                axios
                .post(this.PAYMENT_URL, data, {
                    headers: { Authorization: `Bearer ${this.SESSION_DATA}` },
                })
                .then((res) => {
                    if (res.data.result === "success") {
                        if (payType === "card" || payType === "sbp") {
                            this.iframeSrc = res.data.redirectURL;
                            this.hideStep4 = true
                            this.hidePaymentFrame = false
                            const SITE_URL = this.$store.state.parentSiteUrl;
                            parent.postMessage({ pressPay: '' }, SITE_URL);
                        }

                        if (payType === "sberpay") {
                            this.hidePaymentFrame = false
                            this.hideStep4 = true
                            if(this.isMobile()){
                                this.iframeSrc = res.data.deepLink;
                                if (this.isIos()){
                                    this.pType = "IOS"
                                    setTimeout(() => { this.iframeSrc = res.data.deepLink.replace("sberpay://", 'sbolpay://') }, 60);
                                    this.iframeSrc = "./zaglushka.html"
                                    setTimeout(() => { this.iframeSrc = res.data.deepLink.replace("sberpay://", 'btripsexpenses://sbolpay/') }, 60);
                                    this.iframeSrc = "./zaglushka.html"
                                    setTimeout(() => { this.iframeSrc = res.data.redirectURL }, 900);
                                }
                                setTimeout(() => { this.iframeSrc = res.data.redirectURL }, 900);
                            } else {
                                this.iframeSrc = res.data.redirectURL;
                            }
                            const SITE_URL = this.$store.state.parentSiteUrl;
                            parent.postMessage({ pressPay: '' }, SITE_URL);
                            // window.open(res.data.deepLink, "_blank");
                        }
                        if (this.paymentCount === 1) {
                            this.checkPayment(paymentId)
                        }
                        resolve("success")
                    }
                    if (res.data.result === "wait") {
                        resolve("wait")
                    }

                    if (res.data.result === "error") {
                        if (this.paymentCount < 4) {
                            setTimeout(() => {
                                this.payment(paymentId, payType);
                            }, 1500);
                            this.paymentCount++;
                        } else {
                            let message = `${res.data.message} ${res.data.resultMessage}. Попробуйте еще раз`;
                            this.$alert(message, "", {
                                confirmButtonText: "ОК",
                                callback: () => {},
                            });
                        }
                        reject(`${res.data.message} ${res.data.resultMessage}`)
                    }
                })
                .catch((err) => {
                console.log("ERROR_MESSAGE", err.response.data.message);
                if (err.request.status == 401) {
                    this.$emit("setSession");
                    setTimeout(() => {
                        this.payment(paymentId, payType);
                    }, 1000);
                }
                });
            })
        },
        async saveAndPayment(paymentId, payType) {
            this.$refs.formRef.saveToCart();

            if (this.$refs.formRef.checked) {
                this.subscribe();
            }
            await this.payment(paymentId, payType);
        },
        openMirRegistration() {
            window.open("https://privetmir.ru/russiatravel/#frame");
        },
        tableTotalPrice() {
            let result = 0;
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                const item = this.GUEST_PARAMS[idx];
                for (
                    let innerIdx = 0;
                    innerIdx < item.adults.tariffIds.length;
                    innerIdx++
                ) {
                    result += item.adults.tariffIds[innerIdx].price;
                }
                for (
                    let innerIdx = 0;
                    innerIdx < item.childs.tariffIds.length;
                    innerIdx++
                ) {
                    result += item.childs.tariffIds[innerIdx].price;
                }
            }
            this.price = result;
            return result;
        },
        async getInformationPages() {
            const url = `${this.BACK_API_URL}/informations/`;
            const resp = await axios(url, {
                method: "GET",
            });
            if (resp.data) {
                return resp.data;
            }
            return null;
        },
        async initStepFour() {
            this.infoPages = await this.getInformationPages();
        },
        handleInfoClick(idx) {
            this.pageIdx = idx;
            this.isOpenInfo = true;
            this.scrollToTop();
            // getAccessToken()
        },
        async subscribe() {
            const payerName = this.$refs.formRef.contactForm.payerName;
            const payerSecondName =
                this.$refs.formRef.contactForm.payerSecondName;
            const payerThirdName =
                this.$refs.formRef.contactForm.payerThirdName;
            const phone = this.$refs.formRef.contactForm.phone;
            const email = this.$refs.formRef.contactForm.email;
            const fio =
                payerSecondName + " " + payerName + " " + payerThirdName;

            const contactData = {
                email: email,
                phone: phone,
                fio: fio,
            };
            uniSubscribe(contactData);
        },
        getClusterId(idx) {
            let clasterIds = [];
            const item = this.ADDED_ROOMS[idx];
            clasterIds.push({ clusterId: item.room.clusterId });
            return clasterIds;
        },
        async removeRoom(idx) {
            let clasterId = this.getClusterId(idx);
            await axios
                .post(this.REMOVE_URL, clasterId, {
                    headers: { Authorization: `Bearer ${this.SESSION_DATA}` },
                })
                .then((res) => {
                    console.log("REMOVE ROOM RESP", res);
                })
                .catch((err) => {
                    console.log("ERROR_MESSAGE", err.response.data.message);
                    if (err.request.status == 400) {
                        this.$emit("returnToStart");
                    }
                    if (err.request.status == 401) {
                        this.$emit("setSession");
                        setTimeout(this.removeRoom, 1000);
                    }
                    if (err.request.status == 500) {
                        this.$emit("setSession");
                        setTimeout(this.removeRoom, 2000);
                    }
                });
        },
        removeAllRooms() {
            for (let idx = 0; idx < this.GUEST_PARAMS.length; idx++) {
                this.removeRoom(idx);
            }
        },
        async goToStep3() {
            this.SET_STEP(3);
            await this.removeAllRooms();
            this.$store.commit("SET_ADDED_ROOMS", null);
        },
        async booking(paymentId, payType) {
            if (!this.isFormValid()) {
                console.log("form invalid", paymentId);
                return -1;
            }
            if (this.paymentCount === 0) {
                this.saveAndPayment(paymentId, payType);
                this.getSpkConfig();
            } else {
                this.payment(paymentId, payType);
            }
            // if (this.paymentCount === 0) {
            //     this.getSpkConfig();
            // }
            this.paymentCount++;
            this.scrollToTop();
            const SITE_URL = this.$store.state.parentSiteUrl;
            parent.postMessage({ pressShowRooms: "" }, SITE_URL);
        },
        isFormValid() {
            if (this.$refs.formRef.$v.contactForm.$invalid) {
                this.$refs.formRef.$v.contactForm.$touch();
                this.$refs.formRef.$v.checkedRule.$touch();
                this.$refs.formRef.scrollToElement("formFirstLine");
                return false;
            }
            if (this.$refs.formRef.$v.checkedRule.$invalid) {
                this.$refs.formRef.$v.checkedRule.$touch();
                this.$refs.formRef.scrollToElement("formFirstLine");
                return false;
            }

            return true;
        },
        isMobile() {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
            return true
            } else return false
        },
        isIos() {
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            return true
            } else return false

        },
        fillPersentValues() {
            let persentValues = this.paymentType.map(item => item.PayProc);
            let uniquePersents = [...new Set(persentValues)]
            uniquePersents.sort((a, b) => a - b)
            let result = []
            for (let idx=0; idx < uniquePersents.length; idx++) {
                let item = {value: uniquePersents[idx], label:`${uniquePersents[idx]}%`}
                result.push(item)
            }
            return result
        },
        fillPaymentWays() {
            let paymentWays = []
            for (let idx=0; idx < this.paymentType.length; idx++) {
                let item = this.paymentType[idx]
                let paymentWay = ""
                if (item.Description.includes("СберБанк")){
                    paymentWay = "СберБанк"
                } else if (item.Description.includes("Альфа-Банк")) {
                    paymentWay = "Альфа-Банк"
                } else if (item.Description.includes("СБП")) {
                    paymentWay = "СБП"
                }
                paymentWays.push(paymentWay)
            }
            const uniquePaymentWays = [...new Set(paymentWays)]
            let result = []
            for (let idx=0; idx < uniquePaymentWays.length; idx++) {
                result.push({value: uniquePaymentWays[idx], label: uniquePaymentWays[idx]})

            }
            return result
        },
        isInstallment(desc){
            if (desc.includes("Рассрочка") && this.totalPrice < 150000)
            {
                return true
            }
            return false
        }
    },
};
</script>
